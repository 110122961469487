import * as React from 'react';

const Buy = () => {
  return (
    <>
      <h2>BUY</h2>
    </>
  );
}

export default Buy;