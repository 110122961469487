import React, { useEffect, useState, useContext }from 'react';
import { AppProvider } from './context/AppContext'

import { AppContext } from './context/AppContext'

import CattleConnect from './app/CattleConnect'

// import './App.css';

function App() {
  // const [state, setState] = useContext(AppContext)

  useEffect(() => {
    // console.log(state)
    // ;(async () => {
    //   const jwt = await AsyncStorage.getItem('jwt')
    //   if(jwt) dispatch({
    //     type: ActionType.SIGN_IN,
    //     payload: { jwt }
    //   })
    // })()
    // setTimeout(() => {
    //   setState(state => ({
    //     ...state,
    //     splash: false
    //   }))
    //   }, 3000)
  }, [])

  // const query_api = async () => {
  //   const response = await fetch('http://localhost:8000/')
  //   console.log(response)
  //   const me = await response.json()
  //   console.log(me)
  // }

  return (
    <AppProvider>
      <CattleConnect />
    </AppProvider>
  );
}

export default App;
