import * as React from 'react'

import NavBar from '../components/NavBar'
import Hero from '../components/Hero'
import Listings from '../components/Listings'

const Home = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <Listings />
    </>
  );
}

export default Home;