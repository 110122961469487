import * as React from 'react'
import { useNavigate } from "react-router-dom"

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

const Hero = () => {
  const navigate = useNavigate()

  function to_sell() {
    navigate('/login')
  }

  function to_buy() {
    navigate('/login')
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container disableGutters maxWidth="xl" sx={{ bgcolor: 'primary.main', height: '400px', color: '#ffffff' }}>
        {/* <Box sx={{ bgcolor: 'primary.main', height: '400px' }}> */}
          <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left'}}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div><h2 style={{ marginBottom: '0' }}>Cattle Connect</h2></div>
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div style={{ display: 'flex', gap: '20px'}}>
                <Button onClick={() => { to_buy() }} sx={{ bgcolor: '#ffffff', color: 'primary.main' }}>Buy</Button>
                <Button onClick={() => { to_sell() }} sx={{ bgcolor: '#ffffff', color: 'primary.main' }}>Sell</Button>
              </div>
            </div>
          </div>
        {/* </Box> */}
      </Container>
    </React.Fragment>
  );
}

export default Hero;