import React, { useState, useEffect, useContext }from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { AppContext } from '../context/AppContext'

const Listings = () => {
  const [app, setApp] = useContext(AppContext)

  const [state, setSate] = useState({
    listings: app.listings,
    categories: app.categories,
    category_filter: null,
    loading: true
  })
  console.log(app)

  function add_filter(category_id) {
    const listings_to_display = (state.category_filter === category_id) ? app.listings : app.listings.filter(l => l.category_id === category_id)
    setSate(s => ({
      ...s,
      listings: listings_to_display,
      category_filter: category_id
    }))
  }

  const search_filter = (event) => {
    const search_term = event.target.value
    const listings_to_display = state.listings.filter(l => l.title.toLowerCase().includes(search_term.toLowerCase()))
    setSate(s => ({
      ...s,
      listings: listings_to_display
    }))
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Filters */}
      <Container disableGutters maxWidth="md">
        <Grid container spacing={4} style={{ justifyContent: 'space-between', alignItems: 'center' }} mt={2} mb={2} pb={3}>
          <>
            { state.categories.map(c => (
              <Grid item key={`categories-${c.id}`}>
                <Button key={c.id} sx={{ bgcolor: 'primary.main', color: '#ffffff' }} onClick={() => add_filter(c.id)}>{c.title}</Button>
              </Grid>
            ))}
          </>
          <>
            <Grid item key={`search`}>
              <TextField onChange={search_filter} id="outlined-basic" variant="outlined" />
            </Grid>
          </>
        </Grid>
      </Container>
      {/* Listings */}
      <Container disableGutters maxWidth="md">
        <Grid container spacing={4}>
        { state.listings.map((listing) => (
          <Grid item key={`listing-${listing.id}`} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {/* <CardMedia component="img" sx={{ pt: '56.25%' }} image="https://source.unsplash.com/random" alt="random" /> */}
              <CardMedia component="img" image="https://source.unsplash.com/random" alt="random" />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {listing.title}
                </Typography>
                <Typography>
                  {listing.description}
                </Typography>
                <Typography>
                  {listing.category_id}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">View</Button>
                <Button size="small">Edit</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Listings;