import React, { useState, createContext } from 'react'

import data from '../data/data.js'

export const AppContext = createContext()

export const AppProvider = props => {
  const [state, setState] = useState({
    jwt: false,
    user: null,
    active_user: false,
    menu: [],
    categories: data.get_categories(),
    breeds: data.get_breeds(),
    regions: data.get_regions(),
    listings: data.get_listings(),
    users: data.get_users(),
    admin: data.get_admin()
  })

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  )
}