module.exports = {
  get_categories: function() {
    const categories = [
      { id: 1, title: 'Beef', key: 'beef', icon: null, image: null },
      { id: 2, title: 'Dairy', key: 'dairy', icon: null, image: null },
      { id: 3, title: 'Calf', key: 'calf', icon: null, image: null },
      { id: 4, title: 'Dairy Beef', key: 'dairy-beef', icon: null, image: null }
    ]

    return categories
  },
  get_breeds: function() {
    const breeds = [
      { id: 1, name: 'Abondance', key: 'abondance', icon: null, image: null },
      { id: 2, name: 'Australian Red', key: 'australian-red', icon: null, image: null },
      { id: 3, name: 'Brown Swiss', key: 'brown-swiss', icon: null, image: null },
      { id: 4, name: 'Dutch Friesian', key: 'dutch-friesian', icon: null, image: null },
      { id: 5, name: 'Fleckvieh', key: 'fleckvieh', icon: null, image: null },
      { id: 6, name: 'Guernsey', key: 'guernsey', icon: null, image: null },
      { id: 7, name: 'Holstein Friesian', key: 'holstein-friesian', icon: null, image: null },
      { id: 8, name: 'Jersey', key: 'jersey', icon: null, image: null },
      { id: 9, name: 'Lineback', key: 'lineback', icon: null, image: null },
      { id: 10, name: 'Normande', key: 'normande', icon: null, image: null },
      { id: 11, name: 'Norwegian Red', key: 'norwegian-red', icon: null, image: null },
      { id: 12, name: 'NZ Holstein Friesian', key: 'nz-holstein-friesian', icon: null, image: null },
      { id: 13, name: 'NZ Jersey', key: 'nz-jersey', icon: null, image: null },
      { id: 14, name: 'Montbeliarde', key: 'montbeliarde', icon: null, image: null },
      { id: 15, title: 'Red Holstein Friesian', key: 'red-holstein-friesian', icon: null, image: null },
      { id: 16, title: 'Viking Red', key: 'viking-red', icon: null, image: null },
      { id: 17, title: 'Young Jersey', key: 'young-jersey', icon: null, image: null }
    ]

    return breeds
  },
  get_regions: function() {
    const regions = [
      { id: 1, title: 'Northland', key: 'northland', island: 'North', image: null },
      { id: 2, title: 'Auckland', key: 'auckland', island: 'North', image: null },
      { id: 3, title: 'Waikato', key: 'waikato', island: 'North', image: null },
      { id: 4, title: 'Bay of Plenty', key: 'bay-of-plenty', island: 'North', image: null },
      { id: 5, title: 'Gisborne', key: 'gisborne', island: 'North', image: null },
      { id: 6, title: 'Hawkes Bay', key: 'hawkes-bay', island: 'North', image: null },
      { id: 7, title: 'Taranaki', key: 'taranaki', island: 'North', image: null },
      { id: 8, title: 'Manawatū-Whanganui', key: 'manawatu-whanganui', island: 'North', image: null },
      { id: 9, title: 'Wellington', key: 'wellington', island: 'North', image: null },
      { id: 10, title: 'Tasman', key: 'tasman', island: 'South', image: null },
      { id: 11, title: 'Nelson', key: 'nelson', island: 'South', image: null },
      { id: 12, title: 'West Coast', key: 'west-coast', island: 'South', image: null },
      { id: 13, title: 'Canterbury', key: 'canterbury', island: 'South', image: null },
      { id: 14, title: 'Otago', key: 'otago', island: 'South', image: null },
      { id: 15, title: 'Southland', key: 'southland', island: 'South', image: null }
    ]

    return regions
  },
  get_users: function() {
    const users = [
      {
        id: 1,
        username: 'johndoe',
        name: 'John Doe',
        email: 'johndoe@cattleconnect.co.nz',
        phone: '0271234567',
        account_verified: true,
        password: 'password',
        preferences: {
          categories: [],
          breeds: [],
          regions: []
        },
        created_at: '',
        updated_at: ''
      },
      {
        id: 2,
        username: 'benvale',
        name: 'Ben Vale',
        email: 'benvale@cattleconnect.co.nz',
        phone: '0217230941',
        account_verified: false,
        password: 'password',
        preferences: {
          categories: [],
          breeds: [],
          regions: []
        },
        created_at: '',
        updated_at: ''
      },
      {
        id: 3,
        username: 'benvale',
        name: 'Will Henley',
        email: 'willhenley@cattleconnect.co.nz',
        phone: '0274723939',
        account_verified: true,
        password: 'password',
        preferences: {
          categories: [],
          breeds: [],
          regions: []
        },
        created_at: '',
        updated_at: ''
      }
    ]

    return users
  },
  get_admin: function() {
    const admin = [
      {
        id: 1,
        username: 'admin',
        name: 'Trudy Cattle Connect',
        email: 'admin@cattleconnect.co.nz',
        phone: '0800123456',
        account_verified: true,
        password: 'password',
        created_at: '',
        updated_at: ''
      }
    ]

    return admin
  },
  /*
  * - price per bull
  * - different price if certain quantity are bought
  *
  */
  get_listings: function() {
    const listings = [
      {
        id: 1,
        breed_id: 12,
        seller_id: 1,
        category_id: 1,
        region: 3,
        title: 'Lorem ipsum dolor sit amet',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        quantity_available: 50,
        price: 1500,
        images: [],
        status: 'approved',
        created_at: '',
        updated_at: '',
        approved_at: '',
        finished_at: ''
      },
      {
        id: 2,
        breed_id: 1,
        seller_id: 3,
        category_id: 2,
        region: 13,
        title: 'Excepteur sint occaecat cupidatat',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        quantity_available: 10,
        price: 1000,
        images: [],
        status: 'approved',
        created_at: '',
        updated_at: '',
        approved_at: '',
        finished_at: ''
      },
      {
        id: 3,
        breed_id: 16,
        seller_id: 3,
        category_id: 2,
        region: 13,
        title: 'Duis aute irure dolor in reprehenderit',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        quantity_available: 5,
        price: 500,
        images: [],
        status: 'draft',
        created_at: '',
        updated_at: '',
        approved_at: '',
        finished_at: ''
      },
      {
        id: 4,
        breed_id: 12,
        seller_id: 1,
        category_id: 3,
        region: 3,
        title: 'Ut enim ad minim veniam',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        quantity_available: 25,
        price: 750,
        images: [],
        status: 'approved',
        created_at: '',
        updated_at: '',
        approved_at: '',
        finished_at: ''
      },
      {
        id: 5,
        breed_id: 8,
        seller_id: 1,
        category_id: 4,
        region_id: 3,
        title: 'Viverra accumsan in nisl nisi',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        quantity_available: 10,
        price: 2500,
        images: [],
        status: 'pending',
        created_at: '',
        updated_at: '',
        approved_at: '',
        finished_at: ''
      }
    ]

    return listings
  }
}