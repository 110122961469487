import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate, useLocation } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { AppContext } from '../context/AppContext'

import navbar from '../data/navbar.js'

const NavBar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [app, setApp] = useContext(AppContext)
  const [state, setState] = useState({
    menu_items: navbar.menu_items(),
    dropdown_items: navbar.dropdown_items(),
  })

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    const filtered_routes = []
    if(app.active_user === true) filtered_routes.push('Login')
    if(location.pathname === '/') filtered_routes.push('Home')

    setState(s => ({
      ...s,
      menu_items: state.menu_items.filter(m => !filtered_routes.includes(m.title))
    }))
    // ;(async () => {
    //   const jwt = await AsyncStorage.getItem('jwt')
    //   if(jwt) dispatch({
    //     type: ActionType.SIGN_IN,
    //     payload: { jwt }
    //   })
    // })()
    // setTimeout(() => {
    //   setState(state => ({
    //     ...state,
    //     splash: false
    //   }))
    //   }, 3000)
  }, [])

  const dummy_logout = () => {
    setApp(a => ({
      ...a,
      active_user: null
    }))
    setState(s => ({
      ...s,
      menu_items: navbar.menu_items()
    }))
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            CATTLE CONNECT
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {state.menu_items.map((m) => (
                <MenuItem key={`menu-${m.id}`} onClick={() => navigate(m.link)}>
                  <Typography textAlign="center">{m.title}</Typography>
                  {/* <Link to={{pathname: `${m.url}`}}>{m.title}</Link> */}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            CATTLE CONNECT
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {state.menu_items.map((m) => (
              // <Link to={m.link}>{m.title}</Link>
              <Button
                key={`menu-${m.id}`}
                onClick={() => navigate(m.link)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {m.title}
              </Button>
            ))}
          </Box>
          { app.active_user && (
            <Box sx={{ flexGrow: 0 }}>
              {/* <Tooltip title="Open settings"> */}
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              {/* </Tooltip> */}
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {state.dropdown_items.map((d) => (
                  <MenuItem key={`dropdown-${d.id}`} onClick={handleCloseUserMenu, dummy_logout}>
                    <Typography textAlign="center">{d.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
