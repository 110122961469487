module.exports = {
  menu_items: function() {
    const menu_items = [
      { id: 1, title: 'Home', key: 'home', link: '/' },
      { id: 2, title: 'Buy', key: 'buy', link: '/buy' },
      { id: 3, title: 'Sell', key: 'sell', link: '/sell' },
      { id: 4, title: 'Login', key: 'login', link: '/login' },
    ]

    return menu_items
  },
  dropdown_items: function() {
    const dropdown_items = [
      { id: 1, title: 'Profile', key: 'profile' },
      { id: 2, title: 'Account', key: 'account' },
      { id: 3, title: 'Dashboard', key: 'dashboard' },
      { id: 4, title: 'Logout', key: 'logout' }
    ]

    return dropdown_items
  }
}