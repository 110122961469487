import React, { useEffect, useContext } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Auth from './Auth';
import Home from './Home';
import Buy from './Buy';
import Login from './Login';

import { AppContext } from '../context/AppContext'

import '../App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a895c',
    }
  }
})

function CattleConnect() {
  const [app, setApp] = useContext(AppContext)

  useEffect(() => {
    // ;(async () => {
    //   const jwt = await AsyncStorage.getItem('jwt')
    //   if(jwt) dispatch({
    //     type: ActionType.SIGN_IN,
    //     payload: { jwt }
    //   })
    // })()
    // setTimeout(() => {
    //   setState(state => ({
    //     ...state,
    //     splash: false
    //   }))
    //   }, 3000)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      { !app.jwt ? (<Auth />) :
        (<Router>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/buy" element={<Buy/>} />
            <Route path="/login" element={<Login/>} />
          </Routes>
        </Router>)
      }
    </ThemeProvider>
  );
}

export default CattleConnect;