import React, { useState, useEffect, useContext } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { AppContext } from '../context/AppContext'

const Login = () => {
  const [app, setApp] = useContext(AppContext)
  const [state, setState] = useState({
    username: '',
    password: ''
  })

  const login = () => {
    if( state.username === 'rxadmin' && state.password === 'SU0sO1BN4a' ) {
      setApp(a => ({
        ...a,
        jwt: true
      }))
    }
  }

  const set_credentials = (event, key) => {
    setState(s => ({
      ...s,
      [key]: event.target.value
    }))
  }

  return (
    <Container component="main" sx={{ bgcolor: 'primary.main', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5" sx={{ fontWeight: '700' }}>
          Real Experience // Dev
        </Typography>
        <Box noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            value={state.username}
            onChange={(e) => set_credentials(e, 'username')}
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            variant="outlined"
            autoFocus
          />
          <TextField
            margin="normal"
            value={state.password}
            onChange={(e) => set_credentials(e, 'password')}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            variant="outlined"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ bgcolor: '#ffffff', color: 'primary.main', mt: 3, mb: 2 }}
              onClick={() => login()}
            >
              Sign In
            </Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

export default Login